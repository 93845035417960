import { useEffect, useContext, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { FaArrowLeft } from 'react-icons/fa6';

const animationDuration = 300;

const SlideOutContext = createContext();

export const SlideOutProvider = ({ children }) => {
    const [open, openSlide] = useState('');

    return (
        <SlideOutContext.Provider
            value={{
                open,
                openSlide,
            }}
        >
            {children}
        </SlideOutContext.Provider>
    );
};

export const useSlideOut = () => useContext(SlideOutContext);

export function SlideOut({
    children,
    id,
    closeLink = null,
    onClose = null,
    onOpen = null,
    closeIcon = '',
}) {
    const { open, openSlide } = useSlideOut();
    const [active, setActive] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (open === id) {
            setActive(true);
            if (onOpen && typeof onOpen === 'function') onOpen();
        } else {
            setActive(false);
        }
    }, [open, id, onOpen]);

    useEffect(() => {
        document.querySelector('body').style.overflow = active
            ? 'hidden'
            : 'auto';
    }, [active]);

    const close = () => {
        openSlide('');
        if (onClose && typeof onClose === 'function') onClose();
        if (closeLink) {
            // wait for the slide out to close
            setTimeout(() => {
                navigate(closeLink);
            }, animationDuration);
        }
    };

    return (
        <div
            className={`slide-out | fixed inset-0 w-full h-full z-[999] ${
                active
                    ? 'opacity-1 visible'
                    : `opacity-0 invisible transition-all duration-${animationDuration}`
            }`}
            id={id}
        >
            <div
                className={`slide-out__overlay | fixed inset-0 bg-gray-500 ${
                    active ? 'opacity-50' : 'opacity-0'
                } transition-opacity duration-${animationDuration}`}
                onClick={close}
            ></div>
            <div
                className={`slide-out__container | fixed right-0 top-0 bottom-0 h-full w-full lg:w-[800px] overflow-y-auto bg-white lg:rounded-tl-xl lg:rounded-bl-xl lg:shadow-lg ${
                    active ? 'translate-x-0' : 'translate-x-full'
                } transition-transform duration-${animationDuration}`}
            >
                <button
                    className="slide-out__close | absolute left-6 top-6 cursor-pointer z-50 p-2 bg-white rounded-lg shadow-md"
                    type="button"
                    onClick={close}
                >
                    {closeIcon === 'close' ? (
                        <IoClose className="w-5 h-5" />
                    ) : closeIcon === 'back' ? (
                        <FaArrowLeft className="w-5 h-5" />
                    ) : (
                        <>
                            <IoClose className="w-5 h-5 hidden lg:inline-block" />
                            <FaArrowLeft className="w-5 h-5 inline-block lg:hidden" />
                        </>
                    )}
                </button>
                <div className="slide-out__content">{children}</div>
            </div>
        </div>
    );
}

export function SlideOutHeader({ children, padding = true }) {
    return (
        <div
            className={`slide-out__header | w-full bg-primary-50 pt-20 lg:pt-6 pb-6 pl-6 ${
                padding ? 'lg:pl-20' : ''
            } pr-6`}
        >
            {children}
        </div>
    );
}

export function SlideOutContent({ children, className = '' }) {
    return <div className={`p-6 ${className}`}>{children}</div>;
}

export default SlideOut;
