import { useState } from 'react';
import { useOutlet } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Main from '../../core-ui/Main';

function Dashboard() {
    const outlet = useOutlet();
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <div className="dashboard | lg:max-h-lvh flex lg:overflow-hidden bg-white lg:bg-gray-100 w-full">
            <Sidebar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div className="flex flex-1 w-full flex-col lg:h-screen lg:overflow-hidden">
                <Header setShowSidebar={setShowSidebar} />
                <Main>{outlet}</Main>
            </div>
        </div>
    );
}

export default Dashboard;
