import { useState, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import useQueryParams from '../../hooks/useQueryParams';

const Select2Input = ({
    label,
    description = '',
    info = '',
    multi = true,
    onChange = null,
    placeholder = '',
    disabled = false,
    className = '',
    options = null,
    syncQuery = false,
    onQuerySync = null,
    syncQueryByLabel = false,
    ...props
}) => {
    const { setQueryParams, getQueryParam } = useQueryParams();
    const [field, meta] = useField(props);
    const { value } = field;
    const { setFieldValue } = useFormikContext();
    const [prevValue, setPrevValue] = useState(field.value);
    const [defaultValue, setDefaultValue] = useState([]);
    const defaultValueSet = useRef(false);
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        setSelectOptions(
            options
                ? options
                : props?.children?.map(option => {
                      const value = option.props.value;
                      const label = option.props.children;
                      return { value, label };
                  })
        );
    }, [options, props.children]);

    // Sync query
    useEffect(() => {
        if (
            prevValue &&
            field.value &&
            prevValue.length !== field.value.length
        ) {
            setPrevValue(field.value);

            if (syncQuery === true) {
                const output = syncQueryByLabel
                    ? field.value.map(item => item.label).join(',')
                    : field.value.map(item => item.value).join(',');
                setQueryParams({ [props.name]: output });
            }
        }
    }, [
        field.value,
        props.name,
        syncQuery,
        prevValue,
        syncQueryByLabel,
        setQueryParams,
    ]);

    // Set initial value from props or query
    useEffect(() => {
        if (!selectOptions?.length) return;

        if (defaultValueSet.current) return;
        defaultValueSet.current = true;

        // Prepare available values
        const availableOptions =
            // Check if group options
            selectOptions?.[0]?.options
                ? selectOptions.reduce((acc, group) => {
                      return acc.concat(group.options);
                  }, [])
                : selectOptions;

        // Set initial value from query
        const pickedItems = [];

        if (syncQuery === true) {
            const queryValue = getQueryParam(props.name);

            if (!queryValue) return;

            const queryArray = queryValue
                .split(',')
                .map(item => item.trim().replace('+', ' '));
            if (!queryArray) return;

            availableOptions.forEach(option => {
                if (syncQueryByLabel) {
                    if (queryArray.includes(option.label)) {
                        pickedItems.push(option);
                    }
                } else {
                    if (queryArray.includes(option.value)) {
                        pickedItems.push(option);
                    }
                }
            });
        } else if (props.value) {
            const providedValues = props.value.map(value => value.value);

            availableOptions.forEach(option => {
                if (providedValues.includes(option.value)) {
                    pickedItems.push(option);
                }
            });
        }

        // Set values
        setFieldValue(props.name, pickedItems);
        setDefaultValue(pickedItems);
        if (onQuerySync) onQuerySync(pickedItems);
    }, [
        props.value,
        props.name,
        selectOptions,
        syncQueryByLabel,
        syncQuery,
        setFieldValue,
        getQueryParam,
        onQuerySync,
    ]);

    const customSelectStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            borderColor: state.isFocused ? '#017cef' : '#D1D5DB',
            '&:hover': {
                borderColor: '#D1D5DB',
            },
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            padding: '8px 10px 8px 4px',
            // Disabled
            ...(disabled && {
                backgroundColor: '#F3F4F6',
                color: '##D1D5DB',
                borderColor: '#D1D5DB',
            }),
        }),
        indicatorSeparator: (base, state) => ({
            display: 'none',
        }),
        dropdownIndicator: (base, state) => {
            return {
                ...base,
                display: 'inline-flex',
                alignItems: 'center',
                padding: '0.5rem 0',
                color: 'rgb(107, 114, 128)',
                height: '1rem',
                width: '1rem',
            };
        },
        clearIndicator: (base, state) => {
            return {
                ...base,
                padding: 6,
            };
        },
        placeholder: (base, state) => ({
            ...base,
            fontSize: '14px',
        }),
        valueContainer: (base, state) => ({
            ...base,
            padding: '0 0.5rem',
        }),
        multiValue: (base, state) => {
            return {
                ...base,
                borderRadius: '2rem',
                backgroundColor: '#edfbff',
                borderWidth: '1px',
                borderColor: '#017cef',
                margin: '0.2rem 0.5rem 0.2rem 0.2rem',
            };
        },
        multiValueLabel: (base, state) => {
            return {
                ...base,
                paddingLeft: '12px',
                color: '#017cef',
            };
        },
        multiValueRemove: (base, state) => {
            return {
                ...base,
                color: '#6B7280',
                transition: 'all 150ms',
                '&:hover': {
                    backgroundColor: '#B91C1C',
                    color: '#ffffff',
                    borderRadius: '0 2rem 2rem 0',
                },
            };
        },
        singleValue: (base, state) => ({
            ...base,
            fontSize: '14px',
        }),
        input: (base, state) => {
            return {
                ...base,
                lineHeight: '1',
                fontSize: '14px',
                margin: 0,
                padding: '0px',
                outline: 'none',
                outlineOffset: 0,
                '& input': {
                    boxShadow: 'none !important',
                },
            };
        },
        menu: (base, state) => {
            return {
                ...base,
                zIndex: '2',
            };
        },
    };

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}

            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <Select
                isMulti={multi}
                styles={customSelectStyles}
                {...field}
                options={selectOptions}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder ? placeholder : 'Select...'}
                className="input-field input-field--select-2"
                isDisabled={disabled}
                onChange={values => {
                    const fValues =
                        values.constructor !== Array ? [values] : values;
                    setFieldValue(field.name, fValues);
                    setFieldValue(field.name, fValues);
                    if (onChange) onChange(fValues);
                }}
            />
            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default Select2Input;
