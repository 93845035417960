import { useAuthStatus } from '../../context/AuthContext';
import { XMarkIcon } from '@heroicons/react/24/outline';

import Logo from '../../assets/images/logo-alt.png';

import adminNav, { salesNav, recruiterNav } from '../../navigations/adminNav';
import managerNav from '../../navigations/managerNav';
import clientNav from '../../navigations/clientNav';
import proNav from '../../navigations/proNav';
import proNavPending from '../../navigations/proNavPending';

import { scrollbarClassesDark } from '../../inc/utils';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import BookCallWidget from './modules/BookCallWidget';
import BookCallForm from './modules/BookCallForm';
import { useGlobal } from '../../context/GlobalContext';

function Sidebar({ showSidebar, setShowSidebar }) {
    const { claims } = useAuthStatus();
    const { navNotification } = useGlobal();
    const location = useLocation();
    const [nav, setNav] = useState([]);

    // Set nav
    useEffect(() => {
        if (claims.role === 'admin') setNav(adminNav);
        if (claims.role === 'sales') setNav(salesNav);
        if (claims.role === 'recruiter') setNav(recruiterNav);
        if (claims.role === 'manager') setNav(managerNav);
        if (claims.role === 'client') setNav(clientNav);
        if (claims.role === 'pro' && claims.status === 'pending')
            setNav(proNavPending);
        if (claims.role === 'pro' && claims.status === 'active') setNav(proNav);
    }, [claims]);

    // Close sidebar on route change
    useEffect(() => {
        setShowSidebar(false);
    }, [location, setShowSidebar]);

    return (
        <>
            <div
                className={`sidebar-overlay | fixed inset-0 bg-black bg-opacity-60 z-40 transition-opacity ease-in-out duration-300 ${
                    showSidebar
                        ? 'opacity-100'
                        : 'opacity-0 pointer-events-none'
                }`}
                onClick={() => setShowSidebar(false)}
            >
                <button className="sidebar-close | absolute top-4 right-4 p-2 text-gray-500 bg-white rounded-full focus:outline-none">
                    <XMarkIcon className="h-6 w-6" />
                </button>
            </div>
            <div
                className={`sidebar | fixed left-0 top-0 z-50 lg:relative flex flex-col h-lvh overflow-auto w-64 bg-white border-r border-gray-200 pt-4 px-4 pb-4 gap-8 ${scrollbarClassesDark} ${
                    showSidebar ? 'translate-x-0' : '-translate-x-full'
                } transition-transform ease-in-out duration-300 lg:transform-none`}
            >
                <div className="sidebar-logo | pl-3 hidden lg:block">
                    <img
                        className="w-36 md:w-44"
                        src={Logo}
                        alt="Quickly Hire"
                    />
                </div>
                <nav className="sidebar-nav | flex flex-col mb-4">
                    {nav.map(item => (
                        <NavLink
                            key={item.title}
                            to={`/${item.href}`}
                            className={({ isActive }) =>
                                `flex items-center px-3 py-2.5 gap-3 rounded-xl ${
                                    isActive ||
                                    (item.overrideActiveKey &&
                                        location.pathname.includes(
                                            item.overrideActiveKey
                                        ))
                                        ? 'bg-primary-100 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
                                        : 'bg-white text-gray-500 font-medium hover:bg-gray-50 hover:text-gray-900'
                                }`
                            }
                        >
                            {typeof item.icon === 'object' &&
                            item.icon.outline ? (
                                location.pathname.includes(item.href) ? (
                                    <item.icon.solid className="h-6 w-6 flex-shrink-0" />
                                ) : (
                                    <item.icon.outline className="h-6 w-6 flex-shrink-0" />
                                )
                            ) : (
                                <item.icon className="h-6 w-6 flex-shrink-0" />
                            )}
                            <span className="relative inline-block">
                                {item.title}
                                {navNotification?.[item.href] && (
                                    <span className="absolute top-0.5 -right-2 rounded-full inline-flex w-1.5 h-1.5 bg-red-500"></span>
                                )}
                            </span>
                        </NavLink>
                    ))}
                </nav>
                {claims.role === 'client' && <BookCallWidget />}
            </div>
            {claims.role === 'client' && <BookCallForm />}
        </>
    );
}

export default Sidebar;
