import { useNavigate } from 'react-router-dom';

function useQueryParams() {
    const navigate = useNavigate();

    function getQueryParam(key) {
        const currentUrl = new URL(window.location);

        const params = new URLSearchParams(currentUrl.search);

        return params.get(key);
    }

    function setQueryParams(queryObj) {
        const currentUrl = new URL(window.location);
        const params = new URLSearchParams(currentUrl.search);

        Object.keys(queryObj).forEach(key => {
            if (Array.isArray(queryObj[key])) {
                // If the value is an array, then convert it to a comma-separated string
                const value = queryObj[key].join(',');
                params.set(key, value);
            } else {
                params.set(key, queryObj[key]);
            }
        });

        navigate(`${currentUrl.pathname}?${params.toString()}`);
    }

    return { getQueryParam, setQueryParams };
}

export default useQueryParams;
