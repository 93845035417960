import { useEffect, useContext, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { scrollbarClasses } from '../../inc/utils';
import { Button, ButtonOutline } from '../Buttons';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [open, setOpen] = useState('');

    return (
        <ModalContext.Provider
            value={{
                open,
                setOpen,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);

export function Modal({
    children,
    id,
    closeLink = null,
    onClose = null,
    onOpen = null,
    isBig = false,
    required = false,
    dark = false,
    title = '',
    buttons = [],
}) {
    const { open, setOpen } = useModal();
    const [active, setActive] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (open === id) {
            setActive(true);
            if (onOpen && typeof onOpen === 'function') onOpen();
        } else {
            setActive(false);
        }
    }, [open, id, onOpen]);

    useEffect(() => {
        document.querySelector('body').style.overflow = active
            ? 'hidden'
            : 'auto';
    }, [active]);

    const close = () => {
        if (required) return;
        setOpen('');
        if (onClose && typeof onClose === 'function') onClose();
        if (closeLink) navigate(closeLink);
    };

    return (
        <div className="overflow-hidden h-0" id={id}>
            <div
                className={`fixed w-full h-full inset-0 ${
                    dark ? 'bg-primary/95' : 'bg-black/30'
                } cursor-pointer transition duration-300 z-[1001] ${
                    active
                        ? 'opacity-100 backdrop-blur-sm backdrop-opacity-100 visible'
                        : 'opacity-0 backdrop-blur-0 backdrop-opacity-0 invisible'
                }`}
                onClick={close}
            ></div>
            <div
                className={`group fixed top-1/2 left-1/2 z-[1002] bg-white overflow-auto  transition duration-300 -translate-x-1/2 -translate-y-1/2 px-4 ${
                    isBig
                        ? 'lg:rounded-lg py-10 lg:py-6 w-screen h-screen lg:max-h-[95vh] lg:w-[90vw] lg:h-auto'
                        : 'rounded-lg py-6 w-[90%] max-h-[90vh] max-w-[660px] h-auto min-w-[350px]'
                } ${
                    active ? 'opacity-100 visible' : 'opacity-0 invisible'
                } ${scrollbarClasses}`}
            >
                {title && <h2>{title}</h2>}
                {children}
                {buttons.length > 0 && (
                    <div className="flex lg:flex-row-reverse lg:justify-start items-center gap-2 pt-3">
                        {buttons.map(button => {
                            const type = button.type || 'button'; // button, submit
                            const style = button.style || 'solid'; // solid, outline
                            const text = button.text || 'Button';
                            const onClick = button.onClick || close;

                            return style === 'outline' ? (
                                <ButtonOutline
                                    key={text}
                                    type={type}
                                    onClick={onClick}
                                >
                                    {text}
                                </ButtonOutline>
                            ) : (
                                <Button
                                    key={text}
                                    type={type}
                                    onClick={onClick}
                                >
                                    {text}
                                </Button>
                            );
                        })}
                    </div>
                )}
                {!required && (
                    <button
                        className={`absolute top-0 md:top-2 right-0 md:right-2 cursor-pointer p-2 z-[1003]
                            ${
                                isBig
                                    ? 'top-6 right-2'
                                    : 'top-0 md:top-2 right-0 md:right-2'
                            }
                            `}
                        type="button"
                        onClick={close}
                    >
                        <IoClose
                            className={`${isBig ? 'w-8 h-8' : 'w-5 h-5'}`}
                        />
                    </button>
                )}
            </div>
        </div>
    );
}

export default Modal;
