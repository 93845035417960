import { Outlet } from 'react-router-dom';

function RedirectRoute({ children }) {
    if (window.location.pathname.includes('/dashboard')) {
        window.location.href = '/';
    }

    // If location is pattern /e/{engagementId}, redirect to /team/engagement/{engagementId}
    // If location is pattern /e/{engagementId}/sow, redirect to /team/engagement/{engagementId}/sow
    if (window.location.pathname.includes('/e/')) {
        const path = window.location.pathname.split('/');
        const engagementId = path[2];
        const sow = path[3] === 'sow' ? '/sow' : '';
        window.location.href = `/team/engagement/${engagementId}${sow}`;
    }

    return children ? children : <Outlet />;
}

export default RedirectRoute;
