import PropTypes from 'prop-types';

function ButtonsGroup({ children, className = '' }) {
    return (
        <div className={`flex flex-wrap gap-6 items-center ${className}`}>
            {children}
        </div>
    );
}

ButtonsGroup.propTypes = {
    className: PropTypes.string,
};

export default ButtonsGroup;
