import {
    Squares2X2Icon,
    ClipboardDocumentListIcon,
    UserGroupIcon,
    IdentificationIcon,
    RectangleStackIcon,
    Cog8ToothIcon,
    UsersIcon,
    InboxArrowDownIcon,
    BriefcaseIcon,
} from '@heroicons/react/24/outline';

import {
  Squares2X2Icon as Squares2X2IconAlt,
  ClipboardDocumentListIcon as ClipboardDocumentListIconAlt,
  UserGroupIcon as UserGroupIconAlt,
  IdentificationIcon as IdentificationIconAlt,
  RectangleStackIcon as RectangleStackIconAlt,
  Cog8ToothIcon as Cog8ToothIconAlt,
  UsersIcon as UsersIconAlt,
  InboxArrowDownIcon as InboxArrowDownIconAlt,
  BriefcaseIcon as BriefcaseIconAlt,
} from "@heroicons/react/24/solid";

export const adminNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: {
            outline: Squares2X2Icon,
            solid: Squares2X2IconAlt
        }
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: {
            outline: ClipboardDocumentListIcon,
            solid: ClipboardDocumentListIconAlt
        }
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: {
            outline: UserGroupIcon,
            solid: UserGroupIconAlt
        }
    },
    { 
        title: 'Clients', 
        href: 'd/clients', 
        icon: {
            outline: UsersIcon,
            solid: UsersIconAlt 
        }
    },
    {
        title: 'Managers',
        href: 'd/managers',
        icon: {
            outline: IdentificationIcon,
            solid: IdentificationIconAlt
        }
    },
    {
        title: 'Packages',
        href: 'd/packages',
        icon: RectangleStackIcon,
    },
    { title: 'Positions', href: 'd/positions', icon: BriefcaseIcon },
    { title: 'Helpers', href: 'd/helpers', icon: Cog8ToothIcon },
    { title: 'Export', href: 'd/export', icon: InboxArrowDownIcon },
];

export const salesNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: Squares2X2Icon,
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: ClipboardDocumentListIcon,
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: UserGroupIcon,
    },
    { title: 'Clients', href: 'd/clients', icon: UsersIcon },
];

export const recruiterNav = [
    {
        title: 'Overview',
        href: 'd/overview',
        icon: Squares2X2Icon,
    },
    {
        title: 'Engagements',
        href: 'd/engagements',
        icon: ClipboardDocumentListIcon,
    },
    {
        title: 'Professionals',
        href: 'd/professionals',
        icon: UserGroupIcon,
    },
    { title: 'Clients', href: 'd/clients', icon: UsersIcon },
    { title: 'Export', href: 'd/export', icon: InboxArrowDownIcon },
];

export default adminNav;
