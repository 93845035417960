function CharsLimit({ value, charsLimit }) {
    return (
        <p
            className={`mt-2 mb-0 text-xs leading-[18px] ${
                value?.length > charsLimit ? 'text-red-600' : 'text-gray-500'
            }`}
        >
            {value?.length || 0}/{charsLimit} characters
        </p>
    );
}

export default CharsLimit;
