import PropTypes from 'prop-types';

const Row = ({ children, className = '' }) => {
    return (
        <div className={`flex flex-wrap w-auto min-w-full -m-3 ${className}`}>
            {children}
        </div>
    );
};

Row.propTypes = {
    className: PropTypes.string,
};

export default Row;
