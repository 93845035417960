import { useState, useEffect, useRef } from 'react';

import { BiBell } from 'react-icons/bi';
import { scrollbarClasses } from '../../../inc/utils';
import { ButtonLoaderDark } from '../../../core-ui/Loaders';

import NotificationItem from './NotificationItem';

import { getErrorMessage } from '../../../inc/helpers';

import { useAuthStatus } from '../../../context/AuthContext';
import { db } from '../../../firebase.config';
import {
    collection,
    query,
    where,
    onSnapshot,
    getDocs,
    orderBy,
    limit,
    startAfter,
    endBefore,
    doc,
    updateDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { useGlobal } from '../../../context/GlobalContext';

function Notifications() {
    const { user } = useAuthStatus();
    const { dispatch } = useGlobal();

    const notificationsObserver = useRef(null);
    const loadMoreTrigger = useRef(null);

    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [count, setCount] = useState(0);

    const [shouldFetch, setShouldFetch] = useState(false);
    const [fetching, setFetching] = useState(false);

    const isMounted = useRef(false);
    const isNewNotificationsMounted = useRef(false);

    // Pagination
    const perPage = 5;
    const [lastSnap, setLastSnap] = useState(null);
    const [firstSnap, setFirstSnap] = useState(null);

    // Marking all notifications as read
    const [markingAllAsRead, setMarkingAllAsRead] = useState(false);

    // Close notifications on click outside
    const handleWindowClick = e => {
        if (!e.target.closest('#header-notifications'))
            setShowNotifications(false);
    };

    // Function to get notifications
    const _getNotifications = useCallback(
        async (cursor = null, loadMore = false) => {
            try {
                const queryParts = [
                    collection(db, 'notifications'),
                    where('userId', '==', user.uid),
                    orderBy('timestamp', 'desc'),
                    limit(perPage + 1),
                ];

                if (loadMore && cursor) queryParts.push(startAfter(cursor));

                const notificationsSnap = await getDocs(query(...queryParts));

                const newFirstSnap = notificationsSnap.docs[0] || true;

                const newLastSnap = notificationsSnap.docs[perPage]
                    ? notificationsSnap.docs[notificationsSnap.docs.length - 2]
                    : null;

                const notifications = [];

                notificationsSnap.forEach(doc => {
                    notifications.push({ id: doc.id, ...doc.data() });
                });

                // If we're provided more docs than perPage, then remove the last one
                notificationsSnap.docs.length > perPage && notifications.pop();

                // Finally set the entries
                if (loadMore) {
                    setNotifications(prev => [...prev, ...notifications]);
                } else {
                    setNotifications(notifications);
                }

                return {
                    newFirstSnap,
                    newLastSnap,
                };
            } catch (error) {
                throw error;
            }
        },
        [user.uid]
    );

    // Handle Load More
    const handleLoadMore = async () => {
        try {
            setFetching(true);
            const { newLastSnap } = await _getNotifications(lastSnap, true);

            setLastSnap(newLastSnap);
        } catch (error) {
            toast.error(getErrorMessage(error));
        } finally {
            setFetching(false);
        }
    };

    // Close notifications on scroll
    useEffect(() => {
        document.addEventListener('click', handleWindowClick);

        return () => document.removeEventListener('click', handleWindowClick);
    }, []);

    // Add count to the title
    useEffect(() => {
        if (count) document.title = `(${count}) ${document.title}`;
        else document.title = document.title.replace(`(${count}) `, '');

        return () => {
            document.title = document.title.replace(`(${count}) `, '');
        };
    });

    // Listen to intersection observer to load more
    useEffect(() => {
        if (!loadMoreTrigger.current) return;

        // Listen to intersection observer to load more
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && !fetching) {
                    console.log('load more');
                    handleLoadMore();
                }
            },
            {
                threshold: 0,
            }
        );

        observer.observe(loadMoreTrigger.current);

        // Remove observer on unmount
        return () => observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMoreTrigger.current]);

    // Listen to intersection observer to read notifications
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const isRead = entry.target.dataset.isRead === 'true';

                        if (isRead) return;

                        entry.target.dataset.isRead = 'true';

                        const notificationId = entry.target.dataset.id;

                        // Update notification in db
                        updateDoc(doc(db, 'notifications', notificationId), {
                            isRead: true,
                        });

                        // Update notification in state
                        setNotifications(prev =>
                            prev.map(notification => {
                                if (notification.id === notificationId)
                                    return {
                                        ...notification,
                                        isRead: true,
                                    };

                                return notification;
                            })
                        );

                        // Update new notifications in state if required
                        setNewNotifications(prev =>
                            prev.map(notification => {
                                if (notification.id === notificationId)
                                    return {
                                        ...notification,
                                        isRead: true,
                                    };

                                return notification;
                            })
                        );
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        notificationsObserver.current = observer;
    }, [user.uid]);

    // Get unread notifications count
    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(doc(db, 'users', user.uid, 'private', 'notifications')),
            doc => {
                // Check if doc exists and unreadCount property exists
                if (
                    !doc.exists() ||
                    typeof doc.data()?.unreadCount === 'undefined'
                )
                    return;

                // Set unread count
                setCount(doc.data()?.unreadCount);
                dispatch({
                    type: 'SET_UNREAD_NOTIFICATIONS_COUNT',
                    payload: doc.data()?.unreadCount,
                });
            }
        );

        return () => unsubscribe();
    }, [user.uid, dispatch]);

    // Listen to new notifications
    useEffect(() => {
        if (isNewNotificationsMounted.current || !firstSnap) return;
        isNewNotificationsMounted.current = true;

        const queryParts = [
            collection(db, 'notifications'),
            where('userId', '==', user.uid),
            orderBy('timestamp', 'desc'),
        ];

        if (firstSnap !== true)
            queryParts.push(endBefore(firstSnap.data().timestamp));

        const unsubscribe = onSnapshot(query(...queryParts), querySnapshot => {
            const newNotifications = [];
            querySnapshot.forEach(doc => {
                newNotifications.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            setNewNotifications(newNotifications);
        });

        return () => unsubscribe();
    }, [firstSnap, user.uid]);

    // Get all notifications
    useEffect(() => {
        if (isMounted.current || !shouldFetch) return;
        isMounted.current = true;

        const doAsync = async () => {
            try {
                setFetching(true);

                const { newLastSnap, newFirstSnap } = await _getNotifications();

                setFirstSnap(newFirstSnap);
                setLastSnap(newLastSnap);

                // // Set listener
                // listener = onSnapshot(
                //     query(
                //         collection(db, 'notifications'),
                //         where('userId', '==', user.uid),
                //         orderBy('timestamp', 'desc'),
                //         endBefore(newFirstSnap.data().timestamp)
                //     ),

                // );
            } catch (error) {
                console.error(error);
                toast.error(getErrorMessage(error));
            } finally {
                setFetching(false);
            }
        };

        doAsync();
    }, [_getNotifications, setFetching, user.uid, shouldFetch]);

    // Mark all notifications as read
    const handleMarkAllAsRead = async () => {
        try {
            setMarkingAllAsRead(true);

            // Get all unread notifications from db
            const notificationsSnap = await getDocs(
                query(
                    collection(db, 'notifications'),
                    where('userId', '==', user.uid),
                    where('isRead', '==', false)
                )
            );

            const notifications = [];

            notificationsSnap.forEach(doc => {
                notifications.push(doc.id);
            });

            await Promise.all(
                notifications.map(notification =>
                    updateDoc(doc(db, 'notifications', notification), {
                        isRead: true,
                    })
                )
            );

            // Change all notifications to read in state
            setNotifications(prev =>
                prev.map(notification => ({
                    ...notification,
                    isRead: true,
                }))
            );

            // Change all notifications to read in new notifications state
            setNewNotifications(prev =>
                prev.map(notification => ({
                    ...notification,
                    isRead: true,
                }))
            );
        } catch (error) {
            console.error(error);
            toast.error(getErrorMessage(error));
        } finally {
            setMarkingAllAsRead(false);
        }
    };
    return (
        <div
            id="header-notifications"
            className="header-action | inline-flex relative"
        >
            <button
                type="button"
                className="relative inline-flex items-center justify-center h-9 w-9 rounded-lg border border-gray-200 bg-white hover:bg-primary-50 transition"
                onClick={() => {
                    setShowNotifications(prev => !prev);
                    setShouldFetch(true);
                }}
            >
                <span className="sr-only">Show notifications</span>
                <div className="relative w-4 h-4">
                    <BiBell className="fill-primary w-4 h-4" />
                    {count ? (
                        <span className="absolute w-1.5 h-1.5 rounded-full bg-red-600 border border-white top-0 right-0"></span>
                    ) : null}
                </div>
            </button>
            <div
                className={`notifications | absolute right-0 top-[calc(100%_+_5px)] z-10 w-[240px] md:w-[320px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all ${
                    showNotifications
                        ? 'opacity-100 visible'
                        : 'opacity-0 invisible'
                }`}
            >
                <div className="notifications-header text-base font-medium py-2 px-6 border-b border-gray-200 border-solid">
                    Notifications
                </div>
                <div className="notifications-list__wrapper | relative flex">
                    <div
                        className={`notifications-list | flex flex-col max-h-56 overflow-y-auto ${scrollbarClasses} ${
                            showNotifications ? 'h-full' : 'h-0'
                        }`}
                    >
                        {!fetching || lastSnap ? (
                            (notifications && notifications.length) ||
                            (newNotifications && newNotifications.length) ? (
                                [...newNotifications, ...notifications].map(
                                    notification => (
                                        <NotificationItem
                                            key={notification.id}
                                            notification={notification}
                                            observer={
                                                notificationsObserver.current
                                            }
                                        />
                                    )
                                )
                            ) : (
                                <div className="notifications-list__empty | px-6 py-8 ">
                                    No notifications
                                </div>
                            )
                        ) : null}
                        {!fetching && lastSnap && (
                            <div
                                className="notifications-list__load-more-trigger | min-h-4"
                                ref={loadMoreTrigger}
                            />
                        )}
                        {fetching ? (
                            <div className="notification-loading | py-2 pl-6">
                                <ButtonLoaderDark />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="notifications-footer | py-2 px-6 border-t border-solid border-gray-200">
                    <button
                        type="button"
                        className={`text-sm font-medium transition ${
                            count
                                ? 'text-primary hover:text-primary-500'
                                : 'text-gray-400 cursor-default'
                        }`}
                        onClick={handleMarkAllAsRead}
                        disabled={markingAllAsRead || !count}
                    >
                        {markingAllAsRead
                            ? 'Mark all as read...'
                            : 'Mark all as read'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Notifications;
