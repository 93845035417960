import { HiOutlineUserGroup, HiUserGroup } from 'react-icons/hi';
import { HiOutlineUserAdd, HiUserAdd } from 'react-icons/hi';
import { HiOutlineCreditCard, HiCreditCard } from 'react-icons/hi';
import { HiOutlineCurrencyDollar, HiCurrencyDollar } from 'react-icons/hi';

export const clientNav = [
    {
        title: 'Find a Pro',
        href: 'find-pro',
        icon: {
            outline: HiOutlineUserAdd,
            solid: HiUserAdd,
        },
    },
    {
        title: 'Team',
        href: 'team',
        icon: {
            outline: HiOutlineUserGroup,
            solid: HiUserGroup,
        },
    },
    {
        title: 'Billing',
        href: 'billing',
        overrideActiveKey: 'billing',
        icon: {
            outline: HiOutlineCreditCard,
            solid: HiCreditCard,
        },
    },
    {
        title: 'Refer & Earn',
        href: 'refer',
        icon: {
            outline: HiOutlineCurrencyDollar,
            solid: HiCurrencyDollar,
        },
    },
];
export default clientNav;
