import { useEffect, useState } from 'react';
import { useGlobal } from '../../context/GlobalContext';
import { useAuthStatus } from '../../context/AuthContext';
import { Formik, Form } from 'formik';
import { TextInput, PhotoInput } from '../../core-ui/Inputs';
import * as Yup from 'yup';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updateEmail, updatePassword } from 'firebase/auth';
import { db } from '../../firebase.config';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../inc/helpers';
import useStoreFile from '../../hooks/useStoreFile';

import { PageLoader, ButtonLoader } from '../../core-ui/Loaders';
import { Button } from '../../core-ui/Buttons';
import { Row, RowCell } from '../../core-ui/Grid';
import Container from '../../core-ui/Container';
import { v4 as uuid } from 'uuid';

const initialValues = {
    photo: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    businessWebsite: '',
    businessName: '',
    password: '',
    confirmPassword: '',
};

function Settings() {
    const { dispatch } = useGlobal();
    const { uploadFile } = useStoreFile();
    const { user: currentUser, claims } = useAuthStatus();
    const [data, setData] = useState(initialValues);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);

    useEffect(() => {
        document.title = 'Settings - Quickly Hire';
        dispatch({
            type: 'SET_PAGE_TITLE',
            payload: 'My Settings',
        });
    }, [dispatch]);

    useEffect(() => {
        const doAsync = async () => {
            try {
                const docSnap = await getDoc(doc(db, 'users', currentUser.uid));
                const data = docSnap.data();

                console.log(data);

                setCurrentPhoto(data.photo?.medium || data.photo?.full || '');

                setData(prev => ({
                    ...prev,
                    photo: data.photo?.medium || data.photo?.full || '',
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: currentUser.email,
                    phone: data.phone || '',
                    businessWebsite: data.businessWebsite || '',
                    businessName: data.businessName || '',
                }));
            } catch (error) {
                toast.error(getErrorMessage(error));
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        doAsync();
    }, [dispatch, currentUser]);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            if (isSubmitting) return;
            setIsSubmitting(true);

            let promises = [];

            // Update doc data
            const docData = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                businessWebsite: values.businessWebsite,
                businessName: values.businessName,
            };

            // update email
            if (currentUser.email !== values.email) {
                promises.push(updateEmail(currentUser, values.email));
            }

            // Update photo
            if (currentPhoto !== values.photo) {
                let newPhotoURL = '';

                if (currentPhoto && !values.photo) {
                    // Delete photo
                    docData.photo = {};
                } else if (
                    typeof values.photo === 'object' &&
                    values.photo?.[0]
                ) {
                    const file = values.photo[0];
                    const dir = `users/${currentUser.uid}/photo`;
                    const id = uuid();
                    const extension = file.type.replace(/(.*)\//g, '');
                    const path = `${dir}/${id}/${id}.${extension}`;
                    newPhotoURL = await uploadFile(path, file);

                    docData.photo = {
                        id,
                        full: newPhotoURL,
                    };
                }

                // Dispatch new avatar for the header
                dispatch({
                    type: 'SET_USER_AVATAR',
                    payload: newPhotoURL,
                });
            }

            // Update doc data
            promises.push(
                updateDoc(doc(db, 'users', currentUser.uid), docData)
            );

            // Update password
            if (values.password) {
                promises.push(updatePassword(currentUser, values.password));
            }

            // Await promises
            await Promise.all(promises);

            // Reset password inputs
            resetForm({
                values: { ...values, password: '', confirmPassword: '' },
            });

            // Set success message
            toast.success('Successfully updated!');
        } catch (error) {
            toast.error(getErrorMessage(error));
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const validationSchemeObject = {
        photo: Yup.mixed().test('fileSize', 'The file is too large', value => {
            if (!value?.length || typeof value === 'string') return true;
            return value[0].size <= 2 * 1024 * 1024;
        }),
        firstName: Yup.string()
            .trim()
            .required()
            .min(2, 'First name is too short')
            .max(60, 'First name is too long. Max 60 characters.')
            .label('First Name'),
        lastName: Yup.string()
            .trim()
            .required()
            .min(2, 'Last name is too short')
            .max(60, 'Last name is too long. Max 60 characters.')
            .label('Last Name'),
        businessName: Yup.string()
            .max(120, 'Business name is too long. Max 120 characters.')
            .label('Business name'),
        email: Yup.string()
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Invalid email format'
            )
            .max(60, 'Email should be less than 60 characters')
            .label('Email'),
        businessWebsite: Yup.string()
            .max(200, 'Website URL is too long. Max 200 characters.')
            .label('Business website'),
        phone: Yup.string()
            .max(20, 'Phone number is too long. Max 20 characters.')
            .label('Phone'),
        password: Yup.string().test(
            'empty-check',
            'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
            password =>
                !password ||
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(password)
        ),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match'
        ),
    };

    const validationSchema = Yup.object().shape(validationSchemeObject);

    return (
        <>
            {loading ? (
                <div className="profile-loading | lg:px-8 pb-16">
                    <PageLoader />
                </div>
            ) : (
                <Container className="p-8">
                    <Formik
                        initialValues={data}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <Row>
                                    <RowCell
                                        sizes={{
                                            lg: 3,
                                            md: 6,
                                        }}
                                    >
                                        <PhotoInput
                                            name="photo"
                                            info="Please upload a square image measuring at least 300x300 pixels. Accepted file types: jpg, jpeg, gif, png, Max. file size: 2 MB."
                                        />
                                    </RowCell>
                                    <RowCell
                                        sizes={{
                                            lg: 9,
                                            md: 6,
                                        }}
                                    >
                                        <Row>
                                            <RowCell
                                                sizes={{
                                                    lg: 6,
                                                }}
                                            >
                                                <TextInput
                                                    type="text"
                                                    name="email"
                                                    label="Email"
                                                    autoComplete="username"
                                                />
                                            </RowCell>
                                            <RowCell
                                                sizes={{
                                                    lg: 6,
                                                }}
                                            >
                                                <TextInput
                                                    type="text"
                                                    name="phone"
                                                    label="Phone number"
                                                    autoComplete="tel"
                                                />
                                            </RowCell>
                                            <RowCell
                                                sizes={{
                                                    lg: 6,
                                                }}
                                            >
                                                <TextInput
                                                    type="text"
                                                    name="firstName"
                                                    label="First name"
                                                />
                                            </RowCell>
                                            <RowCell
                                                sizes={{
                                                    lg: 6,
                                                }}
                                            >
                                                <TextInput
                                                    type="text"
                                                    name="lastName"
                                                    label="Last name"
                                                />
                                            </RowCell>
                                            {claims.role === 'client' && (
                                                <>
                                                    <RowCell
                                                        sizes={{
                                                            lg: 6,
                                                        }}
                                                    >
                                                        <TextInput
                                                            name="businessName"
                                                            type="text"
                                                            label="Business name"
                                                        />
                                                    </RowCell>
                                                    <RowCell
                                                        sizes={{
                                                            lg: 6,
                                                        }}
                                                    >
                                                        <TextInput
                                                            name="businessWebsite"
                                                            type="text"
                                                            label="Website URL"
                                                        />
                                                    </RowCell>
                                                </>
                                            )}
                                            <RowCell
                                                sizes={{
                                                    lg: 6,
                                                }}
                                            >
                                                <TextInput
                                                    name="password"
                                                    type="password"
                                                    label="New password"
                                                    autoComplete="new-password"
                                                />
                                            </RowCell>
                                            <RowCell
                                                sizes={{
                                                    lg: 6,
                                                }}
                                            >
                                                <TextInput
                                                    name="confirmPassword"
                                                    type="password"
                                                    label="Confirm new password"
                                                    autoComplete="new-password"
                                                />
                                            </RowCell>
                                            <RowCell>
                                                <div className="flex lg:justify-end">
                                                    <Button type="submit">
                                                        {isSubmitting ? (
                                                            <>
                                                                <ButtonLoader className="mr-2" />
                                                                Saving...
                                                            </>
                                                        ) : (
                                                            'Save changes'
                                                        )}
                                                    </Button>
                                                </div>
                                            </RowCell>
                                        </Row>
                                    </RowCell>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            )}
        </>
    );
}

export default Settings;
