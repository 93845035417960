import { Link } from 'react-router-dom';
import { HiChevronLeft } from 'react-icons/hi';
import useQuery from '../../hooks/useQuery';

function ButtonBack({ children, to, className = '', ...props }) {
    const query = useQuery();

    return to ? (
        <Link
            to={query.get('back') ? decodeURIComponent(query.get('back')) : to}
            className={`inline-flex items-center outline-none font-semibold text-base text-primary-500 hover:text-primary-600 focus:text-primary-600 transition group ${className}`}
            {...props}
        >
            <HiChevronLeft className="fill-primary-500 group-hover:fill-primary-600 group-focus:fill-primary-600 transition" />
            <span>{children ? children : 'Go back'}</span>
        </Link>
    ) : (
        <button
            className={`inline-flex items-center font-semibold text-base outline-none text-primary-500 hover:text-primary-600 focus:text-primary-600 transition group ${className}`}
            {...props}
        >
            <HiChevronLeft className="fill-primary-500 group-hover:fill-primary-600 group-focus:fill-primary-600 transition" />
            <span>{children ? children : 'Go back'}</span>
        </button>
    );
}

export default ButtonBack;
