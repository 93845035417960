import { useState, useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import InputContainer from './parts/InputContainer';
import InputLabel from './parts/InputLabel';
import InputDescription from './parts/InputDescription';
import InputError from './parts/InputError';
import InputInfo from './parts/InputInfo';
import { FaTimes, FaCamera } from 'react-icons/fa';
import LoremAvatar from '../../assets/images/lorem-avatar.jpg';

const PhotoInput = ({
    label,
    description = '',
    info = '',
    className = '',
    ...props
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    const { value, ...rest } = field;
    // const [preview, setPreview] = useState(null);
    const [currentImg, setCurrentImg] = useState(null);
    const input = useRef();

    useEffect(() => {
        if (typeof value === 'object' && value?.[0]) {
            setCurrentImg(URL.createObjectURL(value[0]));
        } else if (value && typeof value === 'string') {
            setCurrentImg(value);
        } else {
            setCurrentImg(null);
        }
    }, [value]);

    useEffect(() => {
        if (!field.value) setCurrentImg(null);
    }, [field.value]);

    return (
        <InputContainer className={className}>
            {label ? (
                <InputLabel htmlFor={props.id || props.name}>
                    {label}
                </InputLabel>
            ) : (
                ''
            )}
            {description ? (
                <InputDescription>{description}</InputDescription>
            ) : (
                ''
            )}
            <div className="relative w-80 xl:w-56 max-w-full group">
                <div className="relative w-full pt-[100%]">
                    <div className="absolute w-full h-full inset-0 overflow-hidden rounded-xl">
                        {currentImg ? (
                            <img
                                src={currentImg}
                                alt={label}
                                className="absolute w-full h-full inset-0 object-cover"
                            />
                        ) : (
                            <img
                                src={LoremAvatar}
                                alt={label}
                                className="absolute w-full h-full inset-0 object-cover"
                            />
                        )}
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        if (!currentImg) return;
                        input.current.value = '';
                        setFieldValue(field.name, '');
                        setCurrentImg(null);
                    }}
                    className={`absolute w-10 h-10 bg-white rounded-xl bottom-1 right-1 inline-flex items-center justify-center drop-shadow ${
                        currentImg ? 'z-10' : ''
                    }`}
                >
                    <FaTimes
                        className={`fill-primary-500 hidden ${
                            currentImg ? 'group-hover:inline-block' : ''
                        }`}
                    />
                    <FaCamera
                        className={`fill-primary-500 ${
                            currentImg ? 'group-hover:hidden' : ''
                        }`}
                    />
                </button>

                <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    {...rest}
                    {...props}
                    className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                    ref={input}
                    onChange={event => {
                        setFieldValue(field.name, event.currentTarget.files);
                        if (
                            event.currentTarget?.files &&
                            event.currentTarget.files.length
                        ) {
                            setCurrentImg(
                                URL.createObjectURL(
                                    new Blob([event.currentTarget.files[0]], {
                                        type: event.currentTarget.files[0].type,
                                    })
                                )
                            );
                        }
                    }}
                />
            </div>

            {info && <InputInfo>{info}</InputInfo>}
            {meta.touched && meta.error ? (
                <InputError>{meta.error}</InputError>
            ) : null}
        </InputContainer>
    );
};

export default PhotoInput;
